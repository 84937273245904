.theme-slider{
  .swiper-nav{
    margin-top: 0;
    cursor: pointer;
    [class*=swiper-] {
      opacity: 0;
      position: absolute;
      transform: translateY(-50%);
      top: 50%;
      transition: opacity 0.4s ease-in-out;
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      color: var(--#{$variable-prefix}700);
      background-color: var(--#{$variable-prefix}swiper-nav-bg) !important;
      box-shadow: 0.125rem 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
      z-index: 1;
      margin: 0;
      @include hover-focus {
        background-color: var(--#{$variable-prefix}white);
        color: var(--#{$variable-prefix}700);
        opacity: 1 !important;
      }
    }
    .swiper-button-prev{ 
      left: map_get($spacers, 2);
    }
    .swiper-button-next{ right: map_get($spacers, 2); }
  }
  
  @include hover-focus { .swiper-nav { [class*=swiper-] { opacity: 0.5; } } }

  .swiper-button-prev:after,
  .swiper-button-next:after {
    font-family: swiper-icons;
    font-size: $font-size-base;
    font-weight: $font-weight-bolder;
    text-transform: none !important;
    letter-spacing: 0;
    text-transform: none;
    font-variant: initial;
    line-height: 1;
  }
}
.product-slider{
  position: relative;
  @include media-breakpoint-up(lg){
    height: calc(100% - 3.5625rem);
  }

  @include media-breakpoint-up(xl){
    height: calc(100% - 3.3125rem);
  }

  @include media-breakpoint-up(xxl){
    height: calc(100% - 4.75rem);
  }
}

//----------------------------------------------------
//  Home Default Swiper Slider
/*-----------------------------------------------


|   swiper Carousel
-----------------------------------------------*/
@keyframes swiperNavAnimate {
  0% { transform: translateX(-100%); }
  50% { transform: translateX(0); }
  85% { transform: translateX(0); }
  100% { transform: translateX(100%); }
}

.swiper-theme{
  .swiper-pagination-bullets {
    bottom: 0 !important;
    left: 0 !important;
  }
  .swiper-pagination-bullet{
    background: #4d8eba;
    color: inherit;
    border: none;
    padding: 0.5rem !important;
    font: inherit;
    width: 0.8rem;
    height: 0.8rem;
    margin: 0.2rem !important;
    opacity: 0.35;
    position: relative;
    overflow: hidden;
}
  .swiper-pagination-bullet-active{
    opacity: 1;
    background: $warning;
  }
  .swiper-pagination-bullet::after{
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: $warning;
    top: 0;
    left: 0;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
    border-radius: .1875rem;
  }

  //- active
  .swiper-pagination-bullet-active{
    background-color: $warning !important;
  }
  .swiper-pagination-bullet.swiper-pagination-bullet-active::after{
    animation: swiperNavAnimate 5s;
  }
}

.swiper-slide {
  transition: opacity 0.4s ease-in-out;
}

.swiper-slide-next {
  opacity: .50;
}

.iframe-container-div::after { 
    content: ""; 
    background: transparent; 
    width: 100%; 
    height: 100%; 
    min-height: 1px; 
    position: absolute; 
    top: 0; 
}